import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

//IMPORT PAGES
import CheckListDetailsPage from "./checklist/CheckListDetailsPage";
import CompaniesListPage from "./companies/CompaniesListPage";
import CompaniesAddPage from "./companies/CompaniesAddPage";
import CompaniesUpdatePage from "./companies/CompaniesUpdatePage";
import ComplaintsListPage from "./complaints/ListComplaintsPage";
import ComplaintDetailPage from "./complaints/ComplaintDetailsPage";
import ComplaintReportPage from "./complaints/ComplaintReportPage"
import ConductListPage from "./riskMaps/conducts/ConductsListPage";
import ConductAddPage from "./riskMaps/conducts/ConductsAddPage";
import ConductTypeListPage from "./riskMaps/conducts/ConductsTypeList";
import LabelsListPage from "./searcher/labels/LabelsListPage";
import LinksListPage from "./searcher/links/LinksListPage";
import LinksAddPage from "./searcher/links/LinksAddPage";
import QuestionnairesListPage from "./questionnaires/QuestionnairesListPage";
import QuestionnairesAddPage from "./questionnaires/QuestionnairesAddPage";
import QuestionnairesDetailsPage from "./questionnaires/QuestionnariesDetailsPage";
import HomePage from "./HomeScreen";
import RiskMapsListPage from "./riskMaps/RiskMapsListPage";
import RiskMapsDetailsPage from "./riskMaps/RiskMapsDetailsPage";
import RiskMapAddConductPage from "./riskMaps/RiskMapAddConductpage";
import RiskMapAdminPage from "./riskMaps/RiskMapsAdminPage";
import RiskStatsPage from "./riskMaps/RiskMapsResultsPage";
import SearcherPage from "./searcher/SearcherPage";
import Skeleton from "../layout/skeleton/skeleton.component";
import TypologiesListPage from "./riskMaps/typologies/TypologiesListPage";
import UserAddPage from "./users/UserAddPage";
import UserListPage from "./users/UsersListPage";
import UserUpdatePage from "./users/UserUpatePage";
import UserUpdateAdminPage from "./users/UserDetailsPage";
import AlertsListPage from "./alerts/AlertsListPage";
import RepositoryPage from "./repository/RepositoryPage";
import CategoriesListPage from "./companies-categories/CategoriesListPage";
import CategoriesUpdatePage from "./companies-categories/CategoriesUpdatePage";
import CategoriesAddPage from "./companies-categories/CategoriesAddPage";
import RiskMapsReportPage from "./riskMaps/RiskMapsReportPage";
import ComplaintsStatesListPage from "./complaints-states/ComplaintStatesListPage";
import ComplaintStateAddPage from "./complaints-states/ComplaintStateAddPage";
import ComplaintStateUpdatePage from "./complaints-states/ComplaintStateUpdatePage";
import ComplaintActionAddPage from "./complaints-action/ComplaintActionAddPage";
import ComplaintActionUpdatePage from "./complaints-action/ComplaintActionUpdatePage";
import { useSelector } from "react-redux";
import AreasListPage from "./companies-area/AreaListPage";
import AreaAddPage from "./companies-area/AreaAddPage";
import AreaUpdatePage from "./companies-area/AreaUpdatePage";
import CompaniesAdminAreaListPage from "./companies/CompaniesAdminAreaPage";

const RouterPage = () => {

  const path = window.location.href;

  // local variables
  const [role, setRole] = useState("");

  // get store data
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // hooks
  useEffect(() => {
    userInfo && setRole(userInfo.role);
    console.log(path);
  }, [userInfo, path]);



  return (
    <Skeleton>
      <Routes>
        {
          role === "AGENTE DE CUMPLIMIENTO" ? (
            <>
              {window.open(`${path}estados`)}
              <Route
              path="*"
              element={<Navigate replace to={"/estados"} />}
            //element={<Navigate replace to={"/"}/>}
            />
            </>
          ) : (
            <Route
              path="*"
              element={<Navigate replace to={"/"} />}
            //element={<Navigate replace to={"/"}/>}
            />
          )
        }
        {/* HOME */}
        <Route path="/" element={<HomePage />} />
        <Route path="/alertas" element={<AlertsListPage />} />
        <Route path="/buscar" element={<SearcherPage />} />
        <Route path="/checklist/:id" element={<CheckListDetailsPage />} />
        <Route path="/categorias" element={<CategoriesListPage />} />
        <Route path="/categoria/:id" element={<CategoriesUpdatePage />} />
        <Route path="/categoria/agregar" element={<CategoriesAddPage />} />
        <Route path="/estados" element={<ComplaintsStatesListPage />} />
        <Route path="/estados/:id" element={<ComplaintStateUpdatePage />} />
        <Route path="/estados/agregar" element={<ComplaintStateAddPage />} />
        <Route path="/denuncias" element={<ComplaintsListPage />} />
        <Route path="/denuncia/:id" element={<ComplaintDetailPage />} />
        <Route path="/denuncia/reporte/:id" element={<ComplaintReportPage />} />
        <Route path="/denuncia/:id/accion" element={<ComplaintActionAddPage />} />
        <Route path="/denuncia/:id/accion/:actionId" element={<ComplaintActionUpdatePage />} />
        <Route path="/empresas" element={<CompaniesListPage />} />
        <Route path="/empresa/:id" element={<CompaniesUpdatePage />} />
        <Route path="/empresa/areas/:id" element={<CompaniesAdminAreaListPage />} />
        <Route path="/empresa/agregar" element={<CompaniesAddPage />} />
        <Route path="/enlaces" element={<LinksListPage />} />
        <Route path="/enlaces/agregar" element={<LinksAddPage />} />
        <Route path="/cuestionarios" element={<QuestionnairesListPage />} />
        <Route path="/cuestionarios/:id" element={<QuestionnairesDetailsPage />} />
        <Route path="/cuestionarios/agregar" element={<QuestionnairesAddPage />} />
        <Route path="/etiquetas" element={<LabelsListPage />} />
        <Route path="/mapa-de-riesgos" element={<RiskMapsListPage />} />
        <Route path="/mapa-de-riesgos/administrar" element={<RiskMapAdminPage />} />
        <Route path="/mapa-de-riesgos/:id" element={<RiskMapsDetailsPage />} />
        <Route path="/mapa-de-riesgos/agregar-conducta/:id" element={<RiskMapAddConductPage />} />
        <Route path="/mapa-de-riesgos/resultados/:id" element={<RiskStatsPage />} />
        <Route path="/mapa-de-riesgos/tipologias" element={<TypologiesListPage />} />
        <Route path="/mapa-de-riesgos/tipo-conductas" element={<ConductTypeListPage />} />
        <Route path="/mapa-de-riesgos/conductas" element={<ConductListPage />} />
        <Route path="/mapa-de-riesgos/conductas/agregar" element={<ConductAddPage />} />
        <Route path="/mapa-de-riesgos/reporte/:id" element={<RiskMapsReportPage />} />
        <Route path="/repositorio" element={<RepositoryPage />} />
        <Route path="/usuario/actualizar" element={<UserUpdatePage />} />
        <Route path="/usuarios" element={<UserListPage />} />
        <Route path="/usuarios/:id" element={<UserUpdateAdminPage />} />
        <Route path="/usuarios/agregar" element={<UserAddPage />} />

        {/* Área */}
        <Route path="/area" element={<AreasListPage />} />
        <Route path="/area/agregar" element={<AreaAddPage />} />
        <Route path="/area/:id" element={<AreaUpdatePage />} />

      </Routes>
    </Skeleton>
  );
};

export default RouterPage;
