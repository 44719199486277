import React, { useEffect, useState } from "react";

//COMPONENTS IMPORT
import Alert from "../../components/shared/alerts/material-snack-bar/material-snack-bar.component";
import Loader from "../../components/shared/loaders/spinner/sppinner.component";

//import redux ;
import { useDispatch, useSelector } from "react-redux";
import { listActionsComplaintUser } from "../../redux/actions/complaints-actions.actions";
import { ACTIONS_COMPLAINT_LIST_RESET } from "../../redux/constants/complaints-actions.const";
import { useParams } from "react-router-dom";
import { getCompanyDetails } from "../../redux/actions/companies.actions";

const ComplaintUserDetailsPage = () => {
	const dispatch = useDispatch();

	const params = useParams();

	const [complaintCode, setComplaintCode] = useState("");
	const [thereAreActions, setThereAreActions] = useState(false);
	const actionsList = useSelector((state) => state.actionsComplaintUserList);
	const { loading, error, actions } = actionsList;
	const companyDetail = useSelector((state) => state.companyDetail);
	const {
		loading: loadingDetails,
		company,
	} = companyDetail;

	const getActions = () => {
		dispatch(listActionsComplaintUser(complaintCode));
		setThereAreActions(true);
		if (error) {
			setTimeout(() => {
				dispatch({ type: ACTIONS_COMPLAINT_LIST_RESET });
			}, 2500);
		}
	};

	useEffect(()=>{
		if (!company.name) {
			dispatch(getCompanyDetails(params.id));
		}
	},[dispatch, company, params.id])

	return (
		<div className="authentication py-5 my-5">
			<div className="container">
				{
					loadingDetails ? (
						<Loader />
					) : (
						<div className="row justify-content-center align-items-center">
							<div className={`col-lg-9 col-sm-12`}>
								<div className="card auth_form">
									<div className="header">
										{
											!company.logo ? (
												<>
													<img height={50} src="assets/images/rigoriLogo.png" alt="Rigori" />
												</>
											) : (
												<>
													<img height={50} src={company.logo} alt="Rigori" />
												</>
											)
										}
							
										<div className="container">
											<div className="row clearfix">
												<div className="col-12">
													<h5>Canal de denuncias</h5>
													<p className="pt-3">
														En <b>{company.name}</b>, estamos preocupados por una total transparencia. En este portal puede dar seguimiento a su
														denuncia
													</p>
												</div>
											</div>
										</div>
									</div>
									<div className="body pt-0">
										<div className="container">
											<div className="row justify-content-center">
												<div className="col-sm-8">
													<div className="form-group">
														<input
															type="text"
															className="form-control"
															placeholder="Ingresa el código de tu denuncia"
															value={complaintCode}
															onChange={(e) => {
																setComplaintCode(e.target.value);
															}}
															required
														/>
													</div>
												</div>
												<div className="col-sm-3">
													<button type="button" disabled={!complaintCode?.length} className="btn btn-block btn-primary" onClick={getActions}>
														Buscar
													</button>
												</div>
											</div>
											{error ? (
												<Alert message={error} />
											) : loading ? (
												<div className="row text-center justify-content-center pt-3">
													<div className="col-4">
														<Loader />
													</div>
												</div>
											) : (
												<>
													{thereAreActions && (
														<>
															{!actions?.length ? (
																<div className="row text-center pt-3">
																	<div className="col-12">
																		<h6>No hay diligencias para esta denuncia</h6>
																	</div>
																</div>
															) : (
																<div className="row clearfix pt-3">
																	<div className="col-12 pb-3">
																		<h5>Detalles de la denuncia</h5>
																	</div>

																	{actions.map((action, index) => {
																		if (index === 0) {
																			return (
																				<>
																					<div className="col-12 col-md-4">
																						<div className="form-group">
																							<label className="form_label">Tipo de denuncia</label>
																							<p>{action.complaint.anonymous ? "Anónima" : "Confidencial"}</p>
																						</div>
																					</div>
																					<div className="col-12 col-md-4">
																						<div className="form-group">
																							<label className="form_label">Denunciante</label>
																							<p>{action.complaint.internalComplainant ? "Interno" : "Externo"}</p>
																						</div>
																					</div>
																					<div className="col-12 col-md-4">
																						<div className="form-group">
																							<label className="form_label">Fecha de denuncia</label>
																							<p>{new Date(action.complaint.createdAt).toLocaleString()}</p>
																						</div>
																					</div>
																					{!action.complaint.anonymous && (
																						<div className="col-lg-6 col-md-12">
																							<div className="form-group">
																								<label className="form_label">Nombre:</label>
																								<p>{action.complaint.name}</p>
																							</div>
																						</div>
																					)}
																					{!action.complaint.anonymous && (
																						<div className="col-lg-6 col-md-12">
																							<div className="form-group">
																								<label className="form_label">Apellido:</label>
																								<p>{action.complaint.lastname}</p>
																							</div>
																						</div>
																					)}
																					{!action.complaint.anonymous && (
																						<div className="col-lg-6 col-md-12">
																							<div className="form-group">
																								<label className="form_label">Teléfono:</label>
																								<p>
																									<p>{action.complaint.phone}</p>
																								</p>
																							</div>
																						</div>
																					)}
																					{!action.complaint.anonymous && (
																						<div className="col-lg-6 col-md-12">
																							<div className="form-group">
																								<label className="form_label">Correo:</label>
																								<p>{action.complaint.email}</p>
																							</div>
																						</div>
																					)}
																					<div className="col-12 col-md-6">
																						<div className="form-group">
																							<label className="form_label">Empresa:</label>
																							<p>{action.complaint.company ? action.complaint.company.name : "-"}</p>
																						</div>
																					</div>
																					<div className="col-lg-6 col-md-12">
																						<div className="form-group">
																							<label className="form_label">Conducta denunciada:</label>
																							<p>{action.complaint.conduct ? action.complaint.conduct : "-"}</p>
																						</div>
																					</div>
																					{action.complaint.denounceds && !!action.complaint.denounceds?.length && (
																						<div className="col-12 pt-3">
																							<div className="form-group">
																								<label className="form_label">Identificación de denunciad@s: </label>
																							</div>
																						</div>
																					)}
																					{action.complaint.denounceds &&
																						action.complaint.denounceds.map((denounced) => (
																							<>
																								<div className="col-12 col-md-4">
																									<div className="form-group mb-0">
																										<label className="form_label">Nombre: </label>{" "}
																										<span>
																											{denounced.denouncedName ? denounced.denouncedName : "-"}{" "}
																											{denounced.denouncedLastname ? denounced.denouncedLastname : "-"}
																										</span>
																									</div>
																								</div>
																								<div className="col-12 col-md-4">
																									<div className="form-group mb-0">
																										<label className="form_label">Cargo: </label>{" "}
																										<span>{denounced.denouncedJob ? denounced.denouncedJob : "-"}</span>
																									</div>
																								</div>
																								<div className="col-12 col-md-4"></div>
																							</>
																						))}
																					<div className="col-12 py-4">
																						<div className="form-group">
																							<label className="form_label">Descripción de los hechos:</label>
																							<p>{action.complaint.description}</p>
																						</div>
																					</div>
																					<div className="col-12">
																						<div className="form-group">
																							<label className="form_label">Calificación de denuncia</label>
																							<p>
																								{action.complaint.complaintRate
																									? action.complaint.complaintRate
																									: "Aún no hay calificación para esta denuncia"}
																							</p>
																						</div>
																					</div>
																				</>
																			);
																		} else {
																			return "";
																		}
																	})}

																	<div className="col-12 pb-3">
																		<h5>Estas son las diligencias de su denuncia</h5>
																	</div>
																	<div className="col-sm-12">
																		<ul className="cbp_tmtimeline">
																			{actions.map((action, index) => (
																				<li key={action.id}>
																					<div className="cbp_tmicon bg-info">{index + 1}</div>
																					<div className="cbp_tmlabel">
																						<div className="cbp_tmtime">
																							<span>{new Date(action.createdAt).toLocaleString()}</span>
																						</div>
																						<h6>estado: {action.actionState.name}</h6>
																						{action.title && (
																							<p>
																								<strong>Título de la diligencia:</strong> {action.title}
																							</p>
																						)}
																						<p>{action.description}</p>
																						{!!action.files?.length && (
																							<div className="table-responsive pt-2">
																								<table className="table table-hover mb-0 c_table">
																									<tbody>
																										{action.files.map((file) => (
																											<tr key={file.id}>
																												<td>
																													<span>
																														<a href={file.url} target="_blank" rel="noopener noreferrer">
																															<i className="zmdi zmdi-file-text w25 text-amber"></i> {file.name}
																														</a>
																													</span>
																												</td>
																											</tr>
																										))}
																									</tbody>
																								</table>
																							</div>
																						)}
																					</div>
																				</li>
																			))}
																		</ul>
																	</div>
																</div>
															)}
														</>
													)}
												</>
											)}
										</div>
									</div>
								</div>
								<div className="copyright text-center">
									&copy;
									{new Date().getFullYear()},{" "}
									<span>
										<a href="https://eiteck.com/" target="_blank" rel="noopener noreferrer">
											Eiteck
										</a>
									</span>
									{" & "}
									<span>
										<a href="https://www.rigori.com.ec/" target="_blank" rel="noopener noreferrer">
											Rigori
										</a>
									</span>
								</div>
							</div>
						</div>
					)
				}
			</div>
		</div>
	);
};

export default ComplaintUserDetailsPage;
