import React, { useState, useCallback, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel } from "@mui/material";
// REDUX IMPORT
import { useDispatch, useSelector } from "react-redux";
import { addComplaints } from "../../redux/actions/complaints.actions";
import { COMPLAINTS_ADD_RESET } from "../../redux/constants/complaints.const";
import { getListEmails } from "../../redux/actions/complaints.actions";

//COMPONENTS IMPORT
import Alert from "../../components/shared/alerts/material-snack-bar/material-snack-bar.component";
import Loader from "../../components/shared/loaders/spinner/sppinner.component";
import DropZoneUploader from "../../components/shared/uploaders/single-uploader/dropzone-uploader";
import SelectedFilesList from "../../components/files/selected-files-list/selected-files-list";
import DenouncedCRUD from "./components/denouncedCRUD";
import { getCompanyDetails } from "../../redux/actions/companies.actions";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';


const CreateComplaintPage = () => {

	const params = useParams();

	const companyDetail = useSelector((state) => state.companyDetail);
	const {
		loading: loadingDetails,
		company,
	} = companyDetail;



	const [uploadFiles, setUploadedFiles] = useState([]);
	const onDrop = useCallback(
		(acceptedFiles) => {
			const files = acceptedFiles.map((file) => file);
			setUploadedFiles([].concat(...uploadFiles, files));
		},
		[uploadFiles]
	);
	const dispatch = useDispatch();
	const complaintAdd = useSelector((state) => state.complaintAdd);
	const listEmails = useSelector((state) => state.listEmails);
	const { loading: loadingEmails, error: errorEmails, emails } = listEmails;
	const [searchParams] = useSearchParams();
	const { loading, error, addResult } = complaintAdd;
	const [isAnonymous, setIsAnonymous] = useState(true);
	const [internalComplainant, setInternalComplainant] = useState(true);
	const [terms, setTerms] = useState();
	const [description, setDescription] = useState("");
	const [conduct, setConduct] = useState("");
	const [conductArea, setConductArea] = useState(0);
	const [denounceds, setDenounceds] = useState([]);
	const [wantedKnowProgress, setWantedKnowProgress] = useState(true);
	const [isValid, setIsValid] = useState(false);
	const [name, setName] = useState("");
	const [lastname, setLastname] = useState("");
	const [companyName, setCompanyName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");


	const popover = (body, title) => {
		return (
			<Popover id="popover-basic" style={{minWidth:"60vh"}}>
				<Popover.Header as="h3">{title}</Popover.Header>
				<Popover.Body >
				<p style={{textAlign:"justify"}}>{body}</p>
					
				</Popover.Body>
			</Popover>
		)
	}



	const submitHandler = (e) => {
		window.scrollTo(0, 0);
		e.preventDefault();
		const anonymous = isAnonymous ? 1 : 0;
		if (anonymous === 1) {
			// IS ANONYMOUS
			if ((companyName || searchParams.get("companyId")) && conduct && email && description && terms) {
				dispatch(
					addComplaints(
						uploadFiles,
						anonymous,
						"",
						"",
						email,
						"",
						companyName,
						internalComplainant,
						conductArea,
						conduct,
						description,
						wantedKnowProgress,
						searchParams.get("companyId"),
						denounceds
					)
				);
			} else {
				if ((companyName || searchParams.get("companyId")) && conduct && description && terms){
					dispatch(
						addComplaints(
							uploadFiles,
							anonymous,
							"",
							"",
							"",
							"",
							companyName,
							internalComplainant,
							conductArea,
							conduct,
							description,
							wantedKnowProgress,
							searchParams.get("companyId"),
							denounceds
						)
					);
				}else{
					setIsValid(true);
				}
			}
		} else {
			// IS NOT ANONYMOUS
			if ((companyName || searchParams.get("companyId")) && description && name && lastname && email && phone && conduct && terms) {
				dispatch(
					addComplaints(
						uploadFiles,
						anonymous,
						name,
						lastname,
						email,
						phone,
						companyName,
						internalComplainant,
						conductArea,
						conduct,
						description,
						wantedKnowProgress,
						searchParams.get("companyId"),
						denounceds
					)
				);
			} else {
				setIsValid(true);
			}
		}
		setTimeout(() => {
			setIsValid(false);
		}, 2500);
	};

	const handleDeleteSingleFile = (e, file) => {
		e.preventDefault();
		setUploadedFiles(
			uploadFiles.filter(function (obj) {
				return obj.path !== file.path;
			})
		);
	};

	const resetForm = () => {
		dispatch({ type: COMPLAINTS_ADD_RESET });
		setIsAnonymous(true);
		setInternalComplainant(true);
		setTerms();
		setDescription("");
		setConduct("");
		setConductArea("");
		setWantedKnowProgress(true);
		setName("");
		setLastname("");
		setCompanyName("");
		setEmail("");
		setPhone("");
		setUploadedFiles([]);
		setDenounceds([]);
	};

	useEffect(() => {
		dispatch(getListEmails());
		if (!company) {
			dispatch(getCompanyDetails(params.id));
		} else {
			setCompanyName(company.name)
			console.log(company)
		}
	}, [dispatch, company, params.id]);

	return (
		<div className="authentication">
			<div className="container">
				<div className="row d-flex align-items-center justify-content-center">
					{
						loadingDetails ? (
							<Loader />
						) : (
							<>
								<div className={`col-lg-9 col-sm-12`}>
									<div className="card auth_form">
										<div className="header mt-1 pt-3">
											{
												company &&  !company.logo ? (
													<>
														<img height={isAnonymous ? 75 : 50} src="assets/images/rigoriLogo.png" alt="Rigori" />
													</>
												) : (
													<>
														
														<img height={isAnonymous ? 75 : 50} src={company?.logo} alt="Rigori" />
													</>
												)
											}
											<div className="container">
												<div className="row clearfix">
													<div className="col-12">
														<h5>Canal de denuncias</h5>
														{
															company && (
																<>
																	<p className="pt-3">
																		En <b> {company.name}</b>, estamos preocupados por una total transparencia. Por favor denuncie cualquier irregularidad. (
																		<b>Las denuncias pueden ser anónimas en caso de que el usuario lo desee.</b>){" "}
																	</p>
																</>
															)
														}
													</div>
												</div>
											</div>
											{error && <Alert message={error} />}
											{addResult && (
												<Alert message="Su solicitud ha sido recibida. Uno de nuestros supervisores revisará el caso." severity="success" />
											)}
											{isValid && <Alert message="Por favor llene los campos requeridos" severity="warning" />}
											{loading && <Loader />}
										</div>

										{
											addResult?.code ? (
												<>
													<div className="col-12 text-center">
														<h6><b>Código de seguimiento:</b> </h6> {addResult.code}
													</div>
												</>
											) : ("")
										}

										<div className="body pt-0">
											{addResult ? (
												<div className="container pt-3">
													<div className="row clearfix justify-content-center text-center">

														<div className="col-12">
															<h6>Gracias por haber realizado su denuncia.</h6>
															<button onClick={resetForm} className="btn btn-rigori waves-effect waves-light">
																Crear una nueva denuncia
															</button>
														</div>
													</div>
												</div>
											) : (
												<>
													{errorEmails ? (
														<Alert message={errorEmails} />
													) : loadingEmails ? (
														<div className="text-center">
															<Loader />
														</div>
													) : (
														<>
															<div className="container">
																<div className="row clearfix">
																	<div className="col-12">
																		<div className="form-group mb-0">
																			<label className="form_label">Tipo de denuncia </label>
																			<OverlayTrigger  trigger="click" placement="right" overlay={popover("Tipo de denuncia: Refiere a los 2 tipos de denuncias que pueden ser ingresadas en el canal, Anónima la cual no requiere ingresar los datos de la persona que realiza la denuncia, únicamente un correo para poder solicitarse más detalles de la denuncia realizada e Identificada en la cual se debe ingresar información de contacto para establecer contacto con el agente de cumplimiento y proceder a brindar detalles de la denuncia realizada, en caso de ser necesario.","TIPO DE DENUNCIA")}>
																				<i style={{ marginLeft: "10px" }} className="zmdi zmdi-info zmdi-hc-lg"></i>
																			</OverlayTrigger>
																		</div>

																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={isAnonymous ? true : false}
																					onChange={() => {
																						setIsAnonymous(true);
																					}}
																					inputProps={{ "aria-label": "controlled" }}
																				/>
																			}
																			label="Anónima"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={isAnonymous ? false : true}
																					onChange={() => {
																						setIsAnonymous(false);
																					}}
																					inputProps={{ "aria-label": "controlled" }}
																				/>
																			}
																			label="Identificada"
																		/>
																	</div>
																</div>
															</div>
															{!isAnonymous ? (
																<div className="container">
																	<div className="row clearfix">
																		<div className="col-lg-6 col-md-12">
																			<div className="input-group mb-3">
																				<input
																					type="text"
																					className="form-control"
																					value={name}
																					onChange={(e) => {
																						setName(e.target.value);
																					}}
																					placeholder="Nombre "
																					required
																				/>
																			</div>
																		</div>
																		<div className="col-lg-6 col-md-12">
																			<div className="input-group mb-3">
																				<input
																					type="text"
																					className="form-control"
																					value={lastname}
																					onChange={(e) => {
																						setLastname(e.target.value);
																					}}
																					placeholder="Apellido "
																					required
																				/>
																			</div>
																		</div>
																		<div className="col-lg-6 col-md-12">
																			<div className="input-group mb-3">
																				<input
																					type="email"
																					className="form-control"
																					value={email}
																					onChange={(e) => {
																						setEmail(e.target.value);
																					}}
																					placeholder="Email"
																					required
																				/>
																				<div className="input-group-append">
																					<span className="input-group-text">
																						<i className="zmdi zmdi-email"></i>
																					</span>
																				</div>
																			</div>
																		</div>
																		<div className="col-lg-6 col-md-12">
																			<div className="input-group mb-3">
																				<input
																					type="tel"
																					className="form-control"
																					value={phone}
																					onChange={(e) => {
																						setPhone(e.target.value);
																					}}
																					placeholder="Teléfono"
																					required
																				/>
																				<div className="input-group-append">
																					<span className="input-group-text">
																						<i className="zmdi zmdi-phone"></i>
																					</span>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															) : (
																<div className="col-12">
																	<span>Ingresa tu email para poder contactarnos <b>(Opcional)</b></span>
																	<div className="input-group mb-3">
																		<input
																			type="email"
																			className="form-control"
																			value={email}
																			onChange={(e) => {
																				setEmail(e.target.value);
																			}}
																			placeholder="Email"
																			required
																		/>
																		<div className="input-group-append">
																			<span className="input-group-text">
																				<i className="zmdi zmdi-email"></i>
																			</span>
																		</div>
																	</div>
																</div>
															)}
															<div className="container">
																<div className="row clearfix">
																	{!searchParams.get("companyId") && (
																		<>

																			{/* <div className="col-12">
																			<div className="input-group mb-3">
																				<input
																					type="text"
																					className="form-control"
																					value={companyName}
																					onChange={(e) => {
																						setCompanyName(e.target.value);
																					}}
																					placeholder="Empresa"
																					required
																				/>
																				<div className="input-group-append">
																					<span className="input-group-text">
																						<i className="zmdi zmdi-accounts"></i>
																					</span>
																				</div>
																			</div>
																		</div> */}
																		</>
																	)}
																	<div className="col-lg-12 col-md-12">
																		<div className="form-group mb-0">
																			<label className="form_label">Denunciante</label>
																			<OverlayTrigger  trigger="click" placement="right" overlay={popover("Persona que realiza la denuncia, al ser una denuncia interna se pueden especificar el área desde la cual se denuncia, este campo no es obligatorio.", "DENUNCIANTE")}>
																				<i style={{ marginLeft: "10px" }} className="zmdi zmdi-info zmdi-hc-lg"></i>
																			</OverlayTrigger>
																		</div>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={internalComplainant ? true : false}
																					onChange={() => {
																						setInternalComplainant(true);
																					}}
																					inputProps={{ "aria-label": "controlled" }}
																				/>
																			}
																			label="Interno"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={internalComplainant ? false : true}
																					onChange={() => {
																						setInternalComplainant(false);
																						setConductArea("");
																					}}
																					inputProps={{ "aria-label": "controlled" }}
																				/>
																			}
																			label="Externo"
																		/>
																	</div>
																	{
																		internalComplainant ? (
																			<>
																				<div className="col-lg-12 col-md-12">
																					<div className="input-group mb-3">
																						<input
																							type="text"
																							className="form-control"
																							value={conduct}
																							onChange={(e) => {
																								setConduct(e.target.value);
																							}}
																							placeholder="Conducta denunciada"
																							required
																						/>
																					</div>
																				</div>
																				<div className="col-lg-12 col-md-12 mb-3">
																					<select
																						className="form-control show-tick"
																						onChange={(e) => {
																							setConductArea(e.target.value);
																						}}
																					>
																						<option value={0}>Área de la Empresa (Opcional)</option>
																						{company &&
																							company.areasInUse?.map((area, index) => (
																								<option key={index} value={Number(area.id)}>
																									{area.name}
																								</option>
																							))}
																					</select>
																				</div>
																			</>
																		) : (
																			<>
																				<div className="col-lg-12 col-md-12">
																					<div className="input-group mb-3">
																						<input
																							type="text"
																							className="form-control"
																							value={conduct}
																							onChange={(e) => {
																								setConduct(e.target.value);
																							}}
																							placeholder="Conducta denunciada"
																							required
																						/>
																					</div>
																				</div>
																			</>
																		)
																	}

																	<div className="col-12">
																		<DenouncedCRUD denounceds={denounceds} setDenounceds={setDenounceds} id={params.id} />
																	</div>

																	<div className="col-lg-12 col-md-12">
																		<div className="form-group pb-3">
																			<label className="form_label">Descripción de los hechos</label>
																			{/* <OverlayTrigger  trigger="click" placement="right" overlay={popover("Descripción 4", "DESCRIPCIÓN DE LOS HECHOS")}>
																				<i style={{ marginLeft: "10px" }} className="zmdi zmdi-info zmdi-hc-lg"></i>
																			</OverlayTrigger> */}
																			<textarea
																				type="text-area"
																				rows="3"
																				className="form-control"
																				placeholder="Describa los hechos"
																				value={description}
																				onChange={(e) => {
																					setDescription(e.target.value);
																				}}
																			/>
																		</div>

																	</div>
																	<div className="col-lg-12 col-md-12">
																		<div className="form-group pb-0">
																			<label className="form_label">Anexos</label>
																			{/* <OverlayTrigger  trigger="click" placement="right" overlay={popover("Descripción 5", "ANEXOS")}>
																				<i style={{ marginLeft: "10px" }} className="zmdi zmdi-info zmdi-hc-lg"></i>
																			</OverlayTrigger> */}
																		</div>
																	</div>
																	<div className="col-12">
																		<div className="mb-3 justify-content-center">
																			<DropZoneUploader onDrop={onDrop} />
																			{uploadFiles.length > 0 ? (
																				<>
																					<SelectedFilesList uploadFiles={uploadFiles} handleDeleteSingleFile={handleDeleteSingleFile} />
																				</>
																			) : null}
																		</div>
																	</div>
																	<div className="col-lg-12 col-md-12">
																		<div className="form-group mb-0">
																			<label className="form_label">¿Desea conocer los avances de su denuncia?</label>
																			{/* <OverlayTrigger  trigger="click" placement="right" overlay={popover("Descripción 6", "¿DESEA CONOCER LOS AVANCES DE SU DENUNCIA?")}>
																				<i style={{ marginLeft: "10px" }} className="zmdi zmdi-info zmdi-hc-lg"></i>
																			</OverlayTrigger> */}
																		</div>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={wantedKnowProgress ? true : false}
																					onChange={() => {
																						setWantedKnowProgress(true);
																					}}
																					inputProps={{ "aria-label": "controlled" }}
																				/>
																			}
																			label="Si"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={wantedKnowProgress ? false : true}
																					onChange={() => {
																						setWantedKnowProgress(false);
																					}}
																					inputProps={{ "aria-label": "controlled" }}
																				/>
																			}
																			label="No"
																		/>
																	</div>
																	<div className="col-12">
																		<small>
																			No mantenemos registros de conexión internos que contengan direcciones de Protocolo de Internet (IP), de
																			modo que no habrá ningún tipo de información disponible sobre usted para terceros.
																		</small>
																		<small className="d-block pt-2">
																			Le informamos de que sus datos son tratados confidencialmente y son utilizados exclusivamente de manera
																			interna y para las finalidades indicadas. Por tanto, no cedemos ni comunicamos a ningún tercero sus datos,
																			excepto en los casos legalmente previstos o en caso de que usted nos lo autorice expresamente.
																		</small>
																		<small className="d-block py-3">
																			TODA LA INFOMACIÓN PROPORICIONADA EN SU DENUNCIA MANTEDRÁ EL ESTATUS DE CONFIDENCIAL, SE HARÁ USO DE ESTA
																			PARA REALIZAR SU INVESTIGACIÓN INTERNA.
																		</small>
																		<small className="d-block">
																			Para obtener información sobre el proceso de tu denuncia puedes comunicarte a estos correos de soporte:{" "}
																			{" - "}
																			{emails.map((email, index) => (
																				<>
																					<a key={index} href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
																						{email}
																					</a>
																					{" - "}
																				</>
																			))}
																		</small>
																	</div>
																	<div className="col-lg-12 col-md-12 pt-4">
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={terms ? true : false}
																					onChange={() => {
																						setTerms(!terms);
																					}}
																					inputProps={{ "aria-label": "controlled" }}
																				/>
																			}
																			label="Declaro que la información proporcionada es veraz y que realizo esta denuncia de manera libre y voluntaria y sin presión de ninguna índole."
																		/>
																	</div>
																</div>
															</div>
															<div className="container pt-3">
																<div className="row clearfix justify-content-center">
																	<div className="col-3">
																		<button onClick={submitHandler} className="btn btn-rigori btn-block waves-effect waves-light">
																			Enviar
																		</button>
																	</div>
																</div>
															</div>
														</>
													)}
												</>
											)}
										</div>
									</div>
									<div className="copyright text-center">
										&copy;
										{new Date().getFullYear()},{" "}
										<span>
											<a href="https://eiteck.com/" target="_blank" rel="noopener noreferrer">
												Eiteck
											</a>
										</span>
										{" & "}
										<span>
											<a href="https://www.rigori.com.ec/" target="_blank" rel="noopener noreferrer">
												Rigori
											</a>
										</span>
									</div>
								</div>
							</>
						)
					}
				</div>
			</div>
		</div>
	);
};

export default CreateComplaintPage;
