import React, { useEffect } from "react";
import {  useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
//Components
import { getCompanyDetails } from "../../../redux/actions/companies.actions";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

const DenouncedCRUD = ({ denounceds, setDenounceds, id }) => {

	const dispatch = useDispatch();

	const companyDetail = useSelector((state) => state.companyDetail);
	const {
		company,
	} = companyDetail;

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({
		mode: "onChange",
		/* reValidateMode: "onChange", */
		criteriaMode: "firstError",
		shouldFocusError: true,
	});


	const popover = (body, title) => {
		return (
			<Popover id="popover-basic" style={{minWidth:"60vh"}}>
				<Popover.Header as="h3">{title}</Popover.Header>
				<Popover.Body >
				<p style={{textAlign:"justify"}}>{body}</p>
					
				</Popover.Body>
			</Popover>
		)
	}


	const deletePerson = (name) => {
		const index = denounceds.findIndex((person) => person.denouncedName === name);
		const auxPeople = [...denounceds];
		auxPeople.splice(index, 1);
		setDenounceds(auxPeople);
	};

	const onSubmit = (data) => {
		console.log("Data: ", data);
		setDenounceds([...denounceds, data]);
		reset({
			denouncedName: "",
			denouncedLastname: "",
			denouncedJob: "",
			...(data.areaId !== 0) ? {areaId : 0} : null,
		})
/* 		{
			data.areaId !== 0 ? (
				reset({
					denouncedName: "",
					denouncedLastname: "",
					denouncedJob: "",
					areaId: 0,
				})
			) : (
				reset({
					denouncedName: "",
					denouncedLastname: "",
					denouncedJob: "",
				})
			)
		} */
	};

	useEffect(() => {
		if (!company.name) {
			dispatch(getCompanyDetails(id));
		}


	}, [dispatch, company, id])

	return (

		<form onSubmit={handleSubmit(onSubmit)} className="row">
			<div className="col-lg-12 col-md-12">
				<div className="form-group pb-0">
					<label className="form_label">Identificación de denunciado(s)</label>
					<OverlayTrigger trigger="click" placement="right" overlay={popover(" Persona sobre quien se  realiza la denuncia, al ser una denuncia interna se pueden especificar el área a la que pertenece la persona que se denuncia, este campo no es obligatorio.", "IDENTIFICACIÓN DE DENUNCIADO(S)")}>
						<i style={{ marginLeft: "10px" }} className="zmdi zmdi-info zmdi-hc-lg"></i>
					</OverlayTrigger>
				</div>
			</div>
			<div className="col-12 col-md-5">
				<div className="input-group mb-3">
					<input type="text" className="form-control" placeholder="Nombre" {...register("denouncedName")} required />
				</div>
			</div>
			<div className="col-12 col-md-6">
				<div className="input-group mb-3">
					<input type="text" className="form-control" placeholder="Apellido" {...register("denouncedLastname")} required />
				</div>
			</div>
			<div className="col-12 col-md-5">
				<div className="input-group mb-3">
					<input type="text" className="form-control" {...register("denouncedJob")} placeholder="Cargo" required />
				</div>
			</div>
			{
				company.areasInUse && company.areasInUse.length ? (
					<>
						<div className="col-12 col-md-6">
							<select
								className="form-control show-tick"
								{...register("areaId", {
									required: false,
								})}
							>
								<option value={0}>Área de la Empresa (Opcional)</option>
								{company &&
									company.areasInUse.map((area, index) => (
										<option key={index} value={Number(area.id)}>
											{area.name}
										</option>
									))}
							</select>

							<div className='col-12'>
								{errors.areaId?.type === "required" && <span style={{ color: "red" }}>El Área es requerido.</span>}
							</div>
						</div>
					</>
				) : (
					<div className="col-12 col-md-6">
						<b>No existen áreas registradas</b>
					</div>
				)
			}
			<div className="col-12 col-md-1 text-center">
				<button
					type="submit"
					className="btn btn-success btn-sm btnAddDenunced"
					style={{ height: "2.375rem", marginTop: "-5vh" }} title="Agregar denunciado">
					+
				</button>
			</div>
			<div className="col-12">
				<div className="table-responsive">
					<table className="table table-sm table-hover c_table">
						{!!denounceds?.length && <thead>
							<tr>
								<th>Nombre</th>
								<th>Apellido</th>
								<th>Cargo</th>
								<th>Área</th>
								<th className="text-center">Acciones</th>
							</tr>
						</thead>}
						<tbody>
							{!denounceds?.length ? (
								<tr className="text-center">
									<td colSpan={5}>No hay denunciados</td>
								</tr>
							) : (
								denounceds.map((person, index) => (
									<tr key={index}>
										<td>{person.denouncedName}</td>
										<td>{person.denouncedLastname}</td>
										<td>{person.denouncedJob}</td>
										{/* <td>{company.areasInUse[person.areaIds]}</td> */}
										{
											company.areasInUse.length !== 0 && (
												<td>{company.areasInUse.filter((x) => String(x.id) === String(person.areaId))[0]?.name}</td>
											)
										}
										<td className="text-center">
											<button type="button" className="btn btn-danger btn-sm" onClick={() => deletePerson(person.denouncedName)}>
												<i className="zmdi zmdi-delete"></i>
											</button>
										</td>
									</tr>
								))
							)}
						</tbody>
					</table>
				</div>
			</div>
		</form>
	);
};

export default DenouncedCRUD;
